import Balancer from '@othree/react-wrap-balancer';
import cx from 'classnames';
import { graphql } from 'gatsby';
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import omit from 'lodash.omit';
import { stringifyUrl } from 'query-string';

import { ForwardedRef, useMemo } from 'react';

import Layout from '../components/Layout/Layout';
import {
  Brand,
  FormattedMerchant,
  Merchant,
  MerchantCategory,
  StripType,
} from '../types';
import { filterMissingRequiredFields, formatCurrency } from '../utils';
import useIsSSR from '../utils/use-is-ssr';
import styles from './smartpay-shops.module.scss';

interface Props {
  data: {
    contentful: {
      merchantCollection: { items: Merchant[] };
      merchantCategoryCollection: { items: MerchantCategory[] };
      stripCollection: { items: StripType[] };
    };
  };
}

type MerchantBlockSize = 'regular' | 'large' | 'responsive';

const TOP_MERCHANTS = [
  'merchant_live_zFLtDx58t1rrlPtdylwzbs',
  'merchant_live_DEOibX7sJuu44Oilx0ofMY',
  'merchant_live_14lkBGX1G1foanALnVfVlE',
  'merchant_live_YJ1Lgq5WvP5lnG7r9TX0e0',
  'merchant_live_mtxjLM7Ry88JitLuFzKJ7X',
  'merchant_live_l3LfwX55Opdu1eJdKWCVhL',
  'merchant_live_MJsTyJlbSI8oJGVEXof9J',
  'merchant_live_AsBwiK6DKlnIkGeaOSTim3',
  'merchant_live_3NAa3xQfAlx5AnAxMKU71p',
  'merchant_live_L4AKW9rJPglEaRvENtYqpu',
  'merchant_live_NFxDoDnsa4f1eyVcRhUBGD',
  'merchant_live_djkqa2vq7O9S2hVpLUe2dm',
  'merchant_live_rpDqdmM8dvzzSEA0GPUFz',
  'merchant_live_tr5lLvxuvHvA8hnqMUL6QP',
  'merchant_live_Pk84b1S1UEcDM2nQNBiKU',
  'merchant_live_9Opv7gjgc0YVDnAAq00qPa',
  'merchant_live_PipdkeX4GMgJCs7XeWZxwA',
  'merchant_live_qwTSeDShDcMIVOmFBywCRb',
  'merchant_live_spx4Dv7DNLQFsmHMJSnniX',
  'merchant_live_RqHEzD1ju6fcl7pYXVHJGY',
  'merchant_live_K27QE7MFW82MY5yJw7a3hZ',
  'merchant_live_RVksoTGgAVf14B0gbFzFcC',
  'merchant_live_XsSa409auQzp2A4HcIPenT',
  'merchant_live_gKvFK8YmAbWlzn6t031QsX',
  'merchant_live_ryFiNEzZL8FLDtrwEU2x46',
  'merchant_live_0cbPZtL6d0FbkaddWAOFGX',
  'merchant_live_5gMFQpfqxcodbCvQDieAVc',
  'merchant_live_torNNWncFSBBYm10PCeh0k',
  'merchant_live_ZfXeWwcjpeukozMopr9KRd',
  'merchant_live_T59ZJnrlzT7FRfn3olRI5',
  'merchant_live_QDlFhgAdYpYsTAIM5f8G8c',
  'merchant_live_hVTs91SI1UhpyhHmabdwnf',
  'merchant_live_iaqOeKI0Be8sYJZUwcFZcZ',
  'merchant_live_zWhEhCDOKQo7QINPJR0zZ',
  'merchant_live_1qnQArAxf7kgOB4M5hT0c1',
];

export const shopsPageQuery = graphql`
  query ShopsPageQuery($language: String!, $dev: Boolean!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    contentful {
      stripCollection(
        order: sys_publishedAt_DESC
        limit: 10
        where: { domains_contains_some: "smartpay.co", active: true }
        preview: $dev
      ) {
        items {
          sys {
            id
          }
          title(locale: $language)
          link(locale: $language)
          type
          active(locale: $language)
          domains
          paths
          startsFrom
          endsOn
        }
      }
      merchantCollection(
        order: sys_firstPublishedAt_DESC
        limit: 300
        where: { physicalStore_not: true }
        preview: $dev
      ) {
        items {
          sys {
            id
            firstPublishedAt
          }
          contentfulMetadata {
            tags {
              id
              name
            }
          }
          merchantId
          title(locale: $language)
          titleKana
          description(locale: $language)
          keywords
          logo {
            url
            width
            height
          }
          slug
          url
          highlight
          coverMobile {
            url
            width
            height
          }
          coverDesktop {
            url
            width
            height
          }
          categoryCollection(limit: 5) {
            items {
              slug
              title
            }
          }
          merchantBrandCollection(limit: 5) {
            items {
              sys {
                id
              }
              slug
              url
              title(locale: $language)
              titleKana
              description(locale: $language)
              coverMobile {
                height
                width
                url
              }
              coverDesktop {
                height
                width
                url
              }
              contentfulMetadata {
                tags {
                  name
                  id
                }
              }
              logo {
                url
                width
                height
              }
            }
          }
        }
      }
    }
  }
`;

const BLOCK_SIZE_REGULAR = 'regular';
const BLOCK_SIZE_LARGE = 'large';
const BLOCK_SIZE_ERSPONSIZE = 'responsive';
const DEFAULT_BLOCK_SIZE = BLOCK_SIZE_REGULAR;
const BLOCK_WIDTH = {
  [BLOCK_SIZE_REGULAR]: 175,
  [BLOCK_SIZE_LARGE]: 210,
  [BLOCK_SIZE_ERSPONSIZE]: 175,
};
const BLOCK_LOGO_WIDTH = {
  [BLOCK_SIZE_REGULAR]: 64,
  [BLOCK_SIZE_LARGE]: 80,
  [BLOCK_SIZE_ERSPONSIZE]: 64,
};

const MerchantBlock = ({
  data: merchant,
  className,
  size,
  inputRef,
}: {
  data?: FormattedMerchant;
  className?: string;
  size?: MerchantBlockSize;
  inputRef?: ForwardedRef<HTMLLIElement>;
}) => {
  const campaign = merchant?.campaign;
  const safeSize = size || DEFAULT_BLOCK_SIZE;
  const blockWidth = BLOCK_WIDTH[safeSize];
  const logoWidth = BLOCK_LOGO_WIDTH[safeSize];

  if (!merchant) {
    return null;
  }

  return (
    <li ref={inputRef} className={cx(styles[safeSize], className)}>
      {campaign && (
        <span className={styles.campaign}>{`${
          campaign.discountType === 'amount'
            ? formatCurrency(campaign.discountAmount || 0)
            : `${campaign.discountPercentage || 0}%`
        } OFF`}</span>
      )}
      <a href={merchant.url} target="_blank" rel="noopener noreferrer">
        <figure>
          <img
            src={stringifyUrl({
              url: merchant.coverMobile?.url || '',
              query: { w: 420, fm: 'jpg' },
            })}
            alt=""
            width={blockWidth}
            height={blockWidth}
            className={styles.cover}
          />
          <img
            src={stringifyUrl({
              url: merchant.logo?.url || '',
              query: { w: 160 },
            })}
            alt=""
            width={logoWidth}
            height={logoWidth}
            className={styles.logo}
          />
          <figcaption>
            <Balancer>{merchant.title}</Balancer>
          </figcaption>
        </figure>
      </a>
    </li>
  );
};

const ShopsPage = ({ data }: Props) => {
  const isSSR = useIsSSR();
  const { t, originalPath, i18n } = useI18next();

  const {
    contentful: {
      merchantCollection: { items: merchants },
      stripCollection: { items: strips },
    },
  } = data;

  const validMerchants: FormattedMerchant[] = useMemo(() => {
    return filterMissingRequiredFields<Merchant>(merchants, [
      'slug',
      'url',
      'coverMobile',
      'logo',
    ])
      .map((node) => {
        return {
          id: node.merchantId,
          sys_id: node.sys.id,
          firstPublishedAt: node.sys.firstPublishedAt,
          ...omit(node, ['sys', 'contentfulMetadata', 'categoryCollection']),
          categories: node.categoryCollection?.items.map((item) => item.slug),
          categoryTitles: node.categoryCollection?.items.map(
            (item) => item.title
          ),
          tags: node.contentfulMetadata?.tags.map((t) => t.name),
          type: 'merchant',
        } as any;
      })
      .reduce((list, node) => {
        const brands = [
          omit(node, ['merchantBrandCollection']) as FormattedMerchant,
        ];

        filterMissingRequiredFields<Brand>(
          node.merchantBrandCollection?.items || [],
          ['slug', 'url']
        ).forEach((brand, index) => {
          const id = `${node.merchantId}-${index + 1}`;

          brands.push({
            id, // Start from 1
            sys_id: brand.sys.id,
            firstPublishedAt: brand.sys.firstPublishedAt,
            merchantId: id,
            ...omit(brand, ['sys', 'contentfulMetadata']),
            categories: node.categories,
            categoryTitles: node.categoryTitles,
            tags: brand.contentfulMetadata?.tags,
            type: 'brand',
          } as FormattedMerchant);
        });

        return [...list, ...brands];
      }, []);
  }, [merchants]);

  const availableMerchants = useMemo(
    () =>
      validMerchants.filter((merchant: FormattedMerchant) => {
        return TOP_MERCHANTS.includes(merchant.merchantId || '');
      }),
    [validMerchants, isSSR]
  );

  return (
    <Layout t={t} originalPath={originalPath} i18n={i18n} strips={strips}>
      <section className={styles.hero}>
        <div className={styles.wrapper}>
          <h1>
            <Trans i18nKey="shops-page-title" />
          </h1>
          <h2>
            <Trans i18nKey="shops-page-subtitle" />
          </h2>
        </div>
      </section>
      <section className={styles.container}>
        <div className={styles.content}>
          <div id="main-content" className={cx(styles.box, styles.main)}>
            <div>
              <h3>{t('top-shops-of-this-week')}</h3>
              <ul className={styles.list}>
                {availableMerchants.map((merchant) => (
                  <MerchantBlock
                    data={merchant}
                    key={merchant.slug}
                    size={BLOCK_SIZE_ERSPONSIZE}
                  />
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ShopsPage;
